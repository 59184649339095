import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import useDashboardMobileMode from './useDashboardMobileMode'

/**
 * isProvenHeaderModeReady: indicates that the ProvenHeaderMode is already calculated
 * isMobileMode: is the mode of ProvenHeader. This is True if the current resolution is smDown
 * or if the width of desktop version of proven-header exceeds its container
 *
 * If current location doen't use the proven-header (on RUD), the isMobileMode depends on media useMediaQuery result
 *
 * @returns {{isMobileMode: boolean, isProvenHeaderModeReady: boolean}}
 */
const useProvenHeaderMode = () => {
  const location = useLocation()
  const isProvenHeaderModeReady = useSelector(state => state.app.provenHeaderMode?.isReady)
  const isMobileMode = useSelector(state => state.app.provenHeaderMode?.isMobileMode)

  const isDashboardMobileMode = useDashboardMobileMode()

  const isCongratsPage =
    location?.pathname?.indexOf && location.pathname.indexOf('congratulations') > -1
  const isThankYouPage = location?.pathname?.indexOf && location.pathname.indexOf('thank-you') > -1
  const isAccountInPath = location?.pathname?.indexOf && location.pathname.indexOf('account') > -1

  return isAccountInPath && !isThankYouPage && !isCongratsPage
    ? { isProvenHeaderModeReady: true, isMobileMode: isDashboardMobileMode }
    : { isProvenHeaderModeReady, isMobileMode }
}

export const withProvenHeaderMode = WrappedComponent => props => {
  const { isProvenHeaderModeReady, isMobileMode } = useProvenHeaderMode()
  return (
    <WrappedComponent
      {...props}
      isMobileMode={isMobileMode}
      isProvenHeaderModeReady={isProvenHeaderModeReady}
    />
  )
}

export default useProvenHeaderMode
