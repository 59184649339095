import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const UTM_TO_TRACK_TIKTOK = 'ttclid'
const LOCAL_STORAGE_KEY_TT = 'proven-analytics-ttclid'

function useForAnalytics() {
  const location = useLocation()

  useEffect(() => {
    // Track UTMs
    if (location && location.search) {
      // if UTM contains TIKTOK id
      const ttParam = new URLSearchParams(location.search).get(UTM_TO_TRACK_TIKTOK)
      const storedValue = getCachedTikTokId()
      if (ttParam && ttParam !== storedValue) {
        cacheTikTokId(ttParam)
      }
    }
  }, [])
}

const cacheTikTokId = value => {
  return localStorage.setItem(LOCAL_STORAGE_KEY_TT, value)
}

const getCachedTikTokId = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEY_TT)
}

const getClusterVersion = () => {
  return process && process.env && process.env.CLUSTER_VERSION ? process.env.CLUSTER_VERSION : 'X'
}

export { getCachedTikTokId, useForAnalytics, UTM_TO_TRACK_TIKTOK, getClusterVersion }
