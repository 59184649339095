import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { createProvenTheme } from '../styles/theme-proven'

/**
 * This hook is a clone of 'useHook' with one exception -
 * it uses the breakpoints defined in our custom Proven Theme.
 *
 * It must be used for all new pages and components that use Proven Theme.
 *
 * @returns {"xxl"|"xl"|"lg"|"md"|"sm"|"xs"|string}
 */

const useProvenWidth = () => {
  const theme = createProvenTheme()
  const keys = [...theme.breakpoints.keys].reverse()
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key))
      return !output && matches ? key : output
    }, null) || 'xs'
  )
}

export const withProvenWidth = WrappedComponent => props => {
  const width = useProvenWidth()
  return <WrappedComponent {...props} width={width} />
}

export default useProvenWidth
