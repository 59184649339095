import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { setLeadEmail } from '../actions/account.actions'
import { useDispatch } from 'react-redux'
import { getTokensFromSearch } from '../utils/helpers'

const UTM_LEAD_EMAIL = 'email'

function useForLeadEmailFromUTM() {
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    // Track UTMs
    if (location && location.search) {
      // if UTM contains 'email' prop
      const tokens = getTokensFromSearch(location.search + location.hash)
      const leadEmailToken = tokens?.find(t => t.startsWith(UTM_LEAD_EMAIL))
      const leadEmailSplit = leadEmailToken?.split('=')
      if (leadEmailSplit && leadEmailSplit.length) {
        const leadEmail = decodeURIComponent(leadEmailSplit[1])
        if (leadEmail) dispatch(setLeadEmail(leadEmail))
      }
    }
  }, [location])
}

export default useForLeadEmailFromUTM
