import React from 'react'
import { useSelector } from 'react-redux'
import {
  activeSubscriptionsSelect,
  completedEyeQuizSelector,
  completedSerumQuizSelector,
  completedSkinQuizSelector,
  inactiveSubscriptionsSelect
} from '../utils/selectors'
import { pathOr } from 'ramda'
import {
  hasAccessToPath,
  getRedirectionByEventNameOrPath
} from '../utils/helpers/userTypeAccessHelper'

function useUserTypeAccess() {
  const activeSubscriptions = useSelector(state => activeSubscriptionsSelect(state))
  const inactiveSubscriptions = useSelector(state => inactiveSubscriptionsSelect(state))
  const orders = useSelector(state => pathOr([], ['account', 'orders'], state))
  const completedSkinQuiz = useSelector(state => completedSkinQuizSelector(state))
  const completedEyeQuiz = useSelector(state => completedEyeQuizSelector(state))
  const completedSerumQuiz = useSelector(state => completedSerumQuizSelector(state))

  const hasAccessTo = path => {
    return hasAccessToPath(path, {
      activeSubscriptions,
      inactiveSubscriptions,
      orders,
      completedSkinQuiz,
      completedEyeQuiz,
      completedSerumQuiz
    })
  }

  const getRedirectionPath = path => {
    return getRedirectionByEventNameOrPath(path, {
      activeSubscriptions,
      inactiveSubscriptions,
      orders,
      completedSkinQuiz,
      completedEyeQuiz,
      completedSerumQuiz
    })
  }

  const hasRedirection = path => !!getRedirectionPath(path)

  return {
    hasAccessTo,
    hasRedirection,
    getRedirectionPath
  }
}

export const withUserTypeAccess = WrappedComponent => props => {
  const { hasAccessTo, hasRedirection, getRedirectionPath } = useUserTypeAccess()
  return (
    <WrappedComponent
      {...props}
      hasAccessTo={hasAccessTo}
      hasRedirection={hasRedirection}
      getRedirectionPath={getRedirectionPath}
    />
  )
}

export default useUserTypeAccess
