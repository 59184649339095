import React from 'react'
import { useDispatch } from 'react-redux'
import { convertSubscriptionItemsToPayload } from '../utils/helpers'
import { modifySubscriptionById } from '../actions/account.actions'
import { createNotification } from '../actions/app.actions'

function useSubscriptionModification() {
  const dispatch = useDispatch()

  const addProductToNextSubscription = async (subscription, subscribedProduct) => {
    const subscriptionItems = convertSubscriptionItemsToPayload(
      subscription?.items,
      subscribedProduct
    )
    const oneTimeItems = convertSubscriptionItemsToPayload(subscription?.oneTimeItems)

    await dispatch(
      modifySubscriptionById({
        subscriptionId: subscription.id,
        items: subscriptionItems,
        oneTimeItems: oneTimeItems
      })
    )

    dispatch(
      createNotification(
        'Success!',
        'Items successfully added your subscription. You can manage it in Subscirption'
      )
    )
  }

  return {
    addProductToNextSubscription
  }
}

export const withSubscriptionModification = WrappedComponent => props => {
  const { addProductToNextSubscription } = useSubscriptionModification()
  return <WrappedComponent {...props} addProductToNextSubscription={addProductToNextSubscription} />
}

export default useSubscriptionModification
