import { useDispatch, useSelector } from 'react-redux'
import { usePrice } from './usePrice'
import { addItemToCart, openCart, removeItemFromCart } from '../actions/cart.actions'
import { isItemInCart } from '../utils/helpers'
import { COMBO_PRODUCTS } from '../constants/products'

function useCartAction() {
  const getPriceData = usePrice()
  const dispatch = useDispatch()
  const cartItems = useSelector(state => state?.cart?.items)

  /**
   *Example: addProductToCart({ frequency : 8, productId: 'proven-system'  })
   * It uses default frequency (when oneTime is false/undefined)
   * @param productId
   * @param oneTime
   * @param switchBetweenOneTimeAndSubscription if there is a current subscription in cart and the product to add is one time, it changes the product to one-time. And viceversa
   * @param ctaText
   */
  const addProductToCart = ({
    productId,
    oneTime,
    switchBetweenOneTimeAndSubscription = true,
    ctaText
  }) => {
    const priceItemToAdd = getPriceData({
      strategy: 'defaultPrice',
      query: { productId, oneTime }
    })

    if (!priceItemToAdd) return

    if (COMBO_PRODUCTS.includes(productId)) {
      const priceItemToLookInCart = getPriceData({
        strategy: 'defaultPrice',
        query: { productId, oneTime: !oneTime }
      })

      const priceItemToRemove = isItemInCart({ priceId: priceItemToLookInCart.id, cartItems })
        ? priceItemToLookInCart
        : undefined

      if (!switchBetweenOneTimeAndSubscription && priceItemToRemove) {
        dispatch(openCart())
        return
      }
      if (priceItemToRemove) {
        dispatch(removeItemFromCart(priceItemToRemove.id, false))
      }
    }
    dispatch(addItemToCart({ ctaText, priceItem: priceItemToAdd, shouldCallPreview: false }))
    dispatch(openCart())
  }

  return {
    addProductToCart
  }
}

export default useCartAction
