import { useSelector } from 'react-redux'
import { appEditionSelector } from '../utils/selectors'
import { APP_EDITION_SEPHORA } from '../constants/constants'
import React from 'react'

function useSupportEmail() {
  const appEdition = useSelector(appEditionSelector)
  const supportEmail =
    appEdition === APP_EDITION_SEPHORA ? 'sephora@provenskincare.com' : 'care@provenskincare.com'

  return {
    supportEmail
  }
}

export const withSupportEmail = WrappedComponent => props => {
  const { supportEmail } = useSupportEmail()
  return <WrappedComponent {...props} supportEmail={supportEmail} />
}

export default useSupportEmail
