import React from 'react'
import { getCurrencySymbolByCode, getActiveCurrencyCodes } from '@provenai/shared'
import { useSelector } from 'react-redux'
import {
  currencyBasedOnUserCountryProductsPrice,
  currencyInfoFromUserInfoSelector
} from '../utils/selectors'

const activeCurrencyCodes = getActiveCurrencyCodes()
const DEFAULT_CURRENCY = 'USD'
const DEFAULT_CURRENCY_SYMBOL = '$'
function useCurrency() {
  //TODO once we unify shop products and product items on ENG-901, we can start using products.
  //Now we are using shopProducts since the shopProducts endpoint returns products with the right currency based on database user countryCode
  // (if user is not in DB, it returns products in USD as default)
  // When we unify shop products and product items make sure that we are using a products (or shopProducts) endpoint without passing countryCode as parameter and rely in backend countryCode,
  // It's important to not pass countryCode as parameter to the endpoint to retrieve products, since there are temporary moments where countryCode is empty in redux store and it generates some errors
  const currencyBasedOnProductsPrice = useSelector(currencyBasedOnUserCountryProductsPrice)

  let currency = currencyBasedOnProductsPrice
  const { countryCode } = useSelector(state => currencyInfoFromUserInfoSelector(state))

  if (
    !activeCurrencyCodes.find(
      ac =>
        currency &&
        typeof currency === 'string' &&
        ac &&
        typeof ac === 'string' &&
        ac.toUpperCase() === currency.toUpperCase()
    )
  ) {
    currency = DEFAULT_CURRENCY
  }

  let currencySymbol = getCurrencySymbolByCode(currency)
  if (!currencySymbol) {
    currency = DEFAULT_CURRENCY
    currencySymbol = DEFAULT_CURRENCY_SYMBOL
  }

  return {
    currency,
    currencySymbol,
    currencyToDisplayOrEmptyIfUS: countryCode === 'CA' ? ` ${currency}` : ''
  }
}

export const withCurrency = WrappedComponent =>
  React.forwardRef((props, ref) => {
    const { currency, currencySymbol, currencyToDisplayOrEmptyIfUS } = useCurrency()
    return (
      <WrappedComponent
        ref={ref}
        {...props}
        currency={currency}
        currencySymbol={currencySymbol}
        currencyToDisplayOrEmptyIfUS={currencyToDisplayOrEmptyIfUS}
      />
    )
  })

export default useCurrency
