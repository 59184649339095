import { useSelector } from 'react-redux'
import { pathOr } from 'ramda'

function useCouponApplying() {
  const isCheckingCoupon = useSelector(state => pathOr(null, ['cart', 'isCheckingCoupon'], state))
  const orderDetails = useSelector(state => pathOr(false, ['checkout', 'orderDetails'], state))
  const isThereAValidCouponApplied =
    !!orderDetails.isCouponValid && orderDetails?.couponCodes?.length
  const isThereAnInvalidCouponApplied = orderDetails.isCouponValid === false

  return {
    isThereAnInvalidCouponApplied,
    isThereAValidCouponApplied,
    isCheckingCoupon
  }
}

export default useCouponApplying
