import { useSelector } from 'react-redux'
import { subscriptionsSelect } from '../utils/selectors'

// this hook checks if user has any active/inactive subscription, not product-specific
function useHasSubscription() {
  const subscriptions = useSelector(state => subscriptionsSelect(state))
  return !!subscriptions?.length
}

export default useHasSubscription
