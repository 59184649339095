import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { pathOr } from 'ramda'
import { DAY_EYE_CREAM_PRODUCT, NIGHT_EYE_CREAM_PRODUCT } from '../constants/products'

const eyeCreamPages = [
  'personalized-eye-cream-duo',
  'personalized-day-eye-cream',
  'personalized-night-eye-cream',
  'account/congratulations/eye',
  'account/shop/eye',
  'account/shop/eye/day-eye-cream',
  'account/shop/eye/night-eye-cream'
]
const serumPages = ['account/shop/serum', 'account/congratulations/serum', 'personalized-serum']

function useProductsResultsData() {
  const location = useLocation()

  const isEyeCreamPage =
    location.pathname && eyeCreamPages.some(p => location?.pathname?.includes(p))

  let resultsStateName = 'results'
  if (isEyeCreamPage) {
    resultsStateName = 'eyeCreamQuizResult'
  } else {
    const isSerumPage = location.pathname && serumPages.some(p => location?.pathname?.includes(p))
    resultsStateName = isSerumPage ? 'serumResults' : resultsStateName
  }

  const { environment } = useSelector(state => pathOr({}, ['account', resultsStateName], state))
  const climate = useSelector(state => pathOr([], ['account', resultsStateName, 'climate'], state))
  const airQuality = useSelector(state =>
    pathOr([], ['account', resultsStateName, 'air_quality'], state)
  )
  const waterHardness = useSelector(state =>
    pathOr([], ['account', resultsStateName, 'water_hardness'], state)
  )

  const lifestyle = useSelector(state =>
    pathOr([], ['account', resultsStateName, 'lifestyle'], state)
  )
  const { skin } = useSelector(state => pathOr({}, ['account', resultsStateName], state))

  const productsFromStore = useSelector(state =>
    pathOr([], ['account', resultsStateName, 'products'], state)
  )
  let products = productsFromStore
  if (
    productsFromStore &&
    !Array.isArray(productsFromStore) &&
    typeof productsFromStore === 'object'
  ) {
    //TODO on ENG-1943 once we standardize results endpoint response in backend, we can remove this 'transformations'
    products = Object.keys(productsFromStore).reduce((prds, key) => {
      let eyeProdData
      if ('eye_day' === key) {
        eyeProdData = { ...productsFromStore[key], productId: DAY_EYE_CREAM_PRODUCT }
      } else if ('eye_night' === key) {
        eyeProdData = { ...productsFromStore[key], productId: NIGHT_EYE_CREAM_PRODUCT }
      }
      if (eyeProdData) {
        let fullIngredients = []
        if (eyeProdData.ingredient_full) {
          if (Array.isArray(eyeProdData.ingredient_full)) {
            fullIngredients = [...eyeProdData.ingredient_full]
          } else if (typeof eyeProdData.ingredient_full === 'string') {
            fullIngredients = eyeProdData.ingredient_full.split(',').map(i => i.trim())
          }
          eyeProdData.full_ingredients = fullIngredients
        }

        if (eyeProdData.bullet_points) {
          eyeProdData.features = []
          eyeProdData.bullet_points.forEach((bp, index) => {
            const featureKey = `feature_${index + 1}`
            eyeProdData.features.push([
              {
                [featureKey]: { icon: bp.description_icon, text: bp.description_point }
              }
            ])
          })
        }

        prds.push(eyeProdData)
      }

      //      ingredient_full
      return prds
    }, [])
  }

  return { climate, airQuality, skin, waterHardness, lifestyle, products, environment }
}

export default useProductsResultsData
