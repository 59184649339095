import useActiveCampaign from './use-active-campaign'
import { useSelector } from 'react-redux'
import { FIRST_SET_CAMPAIGNS } from '../constants/configs/campaigns'
import {
  calcNonDuoProductsTotalPrice,
  calcNonSystemProductsTotalPrice,
  formatPrice,
  getDefaultSystemSubscriptionPrice,
  getSystemProduct,
  isOneTimeProduct,
  isPriceIdInProduct
} from '../utils/helpers'
import { EYE_CREAM_PRODUCT, SYSTEM_PRODUCT } from '../constants/products'
import { pathOr } from 'ramda'
import moment from 'moment'
import { usePrice } from './usePrice'
import { combinedProductsSelector, dependentCouponCodeSelector } from '../utils/selectors'
import { DEFAULT_SUBSCRIPTION_PERIOD_UNIT } from '../constants/subscriptions'
import useCurrency from './useCurrency'
import React from 'react'
import useCouponApplying from './useCouponApplying'
import useProvenHeaderMode from './useProvenHeaderMode'
import { lookForFreeGiftCouponDetails } from '../utils/helpers/couponHelper'

const DEFAULT_DISCOUNT_OBJECT = {
  isCouponApplicable: false,
  discountAmount: 0,
  priceAfterDiscount: undefined,
  discountMessage: ''
}

function usePriceWithDiscounts() {
  const { isThereAValidCouponApplied, isCheckingCoupon } = useCouponApplying()
  const { isMobileMode, isProvenHeaderModeReady } = useProvenHeaderMode()

  const { currencySymbol, currencyToDisplayOrEmptyIfUS } = useCurrency()
  const getPriceData = usePrice()
  const products = useSelector(combinedProductsSelector)
  const cartItems = useSelector(state => pathOr({}, ['cart', 'items'], state))

  const activeCampaign = useActiveCampaign()
  const coupon = useSelector(store => store.checkout.coupon)

  const isCouponValid = useSelector(state =>
    pathOr(null, ['checkout', 'orderDetails', 'isCouponValid'], state)
  )
  const couponDetailsByCode = useSelector(state =>
    pathOr(null, ['checkout', 'orderDetails', 'couponDetailsByCode'], state)
  )

  const dependentCouponCode = useSelector(dependentCouponCodeSelector)

  const isCouponApplicableToItem = (couponCode, item) => {
    const couponDetails = getCouponDetailsByCode(couponDetailsByCode, couponCode)
    if (!couponDetails || !isThereAValidCouponApplied) {
      return false
    }

    if (couponDetails.status !== 'active' || couponDetails.applyOn !== 'each_specified_item') {
      return false
    }
    if (!item) {
      return false
    }

    if (!couponDetails?.itemConstraints.length) {
      return true
    }

    if (
      couponDetails?.itemConstraints.some(c => c.itemType === 'plan' && c.constraint === 'none') &&
      couponDetails?.itemConstraints.some(
        c => c.itemType === 'charge' && c.constraint === 'none'
      ) &&
      couponDetails?.itemConstraints.some(c => c.itemType === 'addon' && c.constraint === 'none')
    ) {
      return false
    }

    const constraintObj = couponDetails?.itemConstraints.find(
      c => c.itemType === (item.period ? 'addon' : 'charge')
    )
    if (
      !constraintObj ||
      //      constraintObj.constraint === 'none' ||
      constraintObj.constraint === 'all'
    ) {
      return true
    }

    if (
      constraintObj.constraint === 'specific' &&
      constraintObj?.itemPriceIds?.find(pi => pi === item.priceId)
    ) {
      return true
    }
    if (constraintObj.constraint === 'criteria') {
      console.error(
        `'criteria' constraint is not implemented, use 'specific' instead. Coupon is considered no applicable. Returning false`
      )
    }
    return false
  }

  const lookForItem = (priceId, items) => {
    if (!priceId) {
      console.warn('priceId is required to getDiscountByPriceId')
      return
    }

    const priceObject = getPriceData({
      strategy: 'id',
      query: { priceId }
    })

    if (!priceObject) return

    const itemsToApplyDiscount = items && Object.keys(items).length ? items : cartItems
    return { ...itemsToApplyDiscount[priceId], price: priceObject.price, priceId }
  }

  const getPriceBeforeAnyDiscount = product => {
    const isSkinComboProduct = product?.metadata?.isSystem

    if (isSkinComboProduct) {
      //is a skin combo subscription
      return calcNonSystemProductsTotalPrice({
        products
      })
    }

    const isEyeComboProduct = product?.id === EYE_CREAM_PRODUCT
    if (isEyeComboProduct) {
      //is a eye combo subscription
      return calcNonDuoProductsTotalPrice({
        products
      })
    }

    return product?.one_time_price?.price ? product?.one_time_price?.price : 0
  }

  const getPricesIfCampaignApplies = ({
    showDiscountedCampaignPrice = false,
    byPassCouponAppliedCheck = false,
    priceId,
    priceWithNoDiscounts,
    priceWithPreviousDiscountsApplied
  }) => {
    let priceBeforeDiscounts = priceWithNoDiscounts
    let priceAfterDiscounts = priceWithPreviousDiscountsApplied

    // If the coupon is one of the first set campaigns, show discounted price
    const showPriceWithCouponDiscount = showDiscountedCampaignPrice && activeCampaign

    const campaignSystemPrice = isOneTimeProduct(priceId, products)
      ? activeCampaign?.oneTimePrice
      : activeCampaign?.systemPrice
    const campaignSystemPriceInCents = campaignSystemPrice
      ? campaignSystemPrice * 100 //  it's changed to cents
      : null
    priceAfterDiscounts =
      (coupon === activeCampaign?.promoCode || byPassCouponAppliedCheck) &&
      campaignSystemPriceInCents
        ? campaignSystemPriceInCents
        : priceAfterDiscounts

    //it's added a 'isCouponValid' condition since
    // Without this condition, when it's one time selected, coupon is mark as invalid (in Cart coupon form), however the discount is applied in prices
    const couponAppliedCampaignPrice =
      coupon && isCouponValid && FIRST_SET_CAMPAIGNS[coupon.toUpperCase()]

    priceAfterDiscounts =
      (couponAppliedCampaignPrice?.price ? couponAppliedCampaignPrice.price * 100 : null) ??
      priceAfterDiscounts

    priceBeforeDiscounts =
      showPriceWithCouponDiscount && activeCampaign?.noDiscountPrice
        ? activeCampaign.noDiscountPrice * 100
        : priceBeforeDiscounts

    return { priceBeforeDiscounts, priceAfterDiscounts }
  }

  /**
   *
   * @param priceId
   * @param items (default: cartItems from redux)
   * @returns {{priceAfterDiscounts: (*|number), priceBeforeDiscounts: (*|number), priceAfterDiscountsForNextAutoRefill: (*|number), discountMessage: string}}
   */
  const getDiscountByPriceId = (priceId, items = cartItems) => {
    const item = lookForItem(priceId, items)
    if (!item) {
      return DEFAULT_DISCOUNT_OBJECT
    }

    const product = products.find(someProduct => {
      return isPriceIdInProduct({ product: someProduct, priceId })
    })
    let priceBeforeDiscounts = getPriceBeforeAnyDiscount(product)
    let priceAfterDiscounts = item.price

    let isFreeProduct = false
    //if master coupon or dependent coupon turns the current item in a free-gift, we use that free-gift couponDetails for the current item
    let couponDetails = getCouponDetailsByCode(couponDetailsByCode, coupon)
    const dependentCouponDetails = getCouponDetailsByCode(couponDetailsByCode, dependentCouponCode)
    const freeGiftCouponDetails = [couponDetails, dependentCouponDetails].find(cd =>
      cd?.priceIdsForFreeWithCouponApplying?.includes(priceId)
    )
    if (freeGiftCouponDetails) {
      isFreeProduct = true
      couponDetails = freeGiftCouponDetails
    }

    if (product.id === SYSTEM_PRODUCT) {
      //only for system product, apply campaign
      const campaignPrices = getPricesIfCampaignApplies({
        priceId,
        priceWithNoDiscounts: priceBeforeDiscounts,
        priceWithPreviousDiscountsApplied: priceAfterDiscounts
      })
      if (priceAfterDiscounts !== campaignPrices.priceAfterDiscounts) {
        return {
          ...campaignPrices,
          priceAfterDiscountsForNextAutoRefill: campaignPrices.priceAfterDiscounts,
          discountMessage: '',
          isFreeProduct
        }
      }
    }

    if (!isCouponApplicableToItem(coupon, item)) {
      return {
        priceAfterDiscounts,
        priceBeforeDiscounts,
        priceAfterDiscountsForNextAutoRefill: priceAfterDiscounts,
        discountMessage: '',
        isFreeProduct
      }
    }

    //    const couponDetails = getCouponDetailsByCode(couponDetailsByCode, coupon)

    const priceBeforeItemCouponDiscount = priceAfterDiscounts
    priceAfterDiscounts = getPriceAfterItemDiscount(couponDetails, item.price)

    const couponDurationInPeriodUnits = geCouponDurationInPeriodUnits(couponDetails)

    const discountMessage = getProductDiscountMessage({
      couponDetails,
      item,
      priceAfterDiscounts,
      couponDurationInPeriodUnits
    })

    return {
      priceAfterDiscounts,
      priceBeforeDiscounts,
      priceAfterDiscountsForNextAutoRefill:
        couponDetails.durationType !== 'forever' &&
        (couponDetails.durationType === 'one_time' || couponDurationInPeriodUnits)
          ? priceBeforeItemCouponDiscount
          : priceAfterDiscounts,
      discountMessage,
      isFreeProduct
    }
  }

  const geCouponDurationInPeriodUnits = couponDetails => {
    if (couponDetails.durationType !== 'limited_period') {
      return null
    }
    const duration = moment.duration(couponDetails.period, couponDetails.periodUnit)

    if (DEFAULT_SUBSCRIPTION_PERIOD_UNIT === 'week') {
      return duration.asWeeks()
    } else if (DEFAULT_SUBSCRIPTION_PERIOD_UNIT === 'month') {
      return duration.asMonths()
    }
    console.error('unknown DEFAULT_SUBSCRIPTION_PERIOD_UNIT:' + DEFAULT_SUBSCRIPTION_PERIOD_UNIT)
    return null
  }

  const getNumberOfOrdersWithDiscount = (durationAsNumber, itemPeriod) =>
    Math.round(durationAsNumber / itemPeriod)

  const getProductDiscountMessage = ({
    couponDetails,
    item,
    priceAfterDiscounts,
    couponDurationInPeriodUnits
  }) => {
    //if it's one-time
    if (!item.period) {
      return 'Code applied to this item.'
    }
    //if it's a subscription

    if (couponDetails.durationType === 'one_time') {
      return isProvenHeaderModeReady && !isMobileMode
        ? `Code applied. Future payments will be ${currencySymbol}${formatPrice(
            item.price
          )}${currencyToDisplayOrEmptyIfUS}.`
        : 'Code applied to your first order.'
    }

    if (couponDetails.durationType === 'forever') {
      return 'Code applied to current and all future orders.'
    }

    if (couponDurationInPeriodUnits) {
      if (couponDurationInPeriodUnits < item.period) {
        return 'Code applied to your first order.'
      }
      const numberOfOrdersWithDiscount = getNumberOfOrdersWithDiscount(
        couponDurationInPeriodUnits,
        item.period
      )

      return `Code applied. Your first ${
        numberOfOrdersWithDiscount === 1 ? 'order' : numberOfOrdersWithDiscount + ' orders'
      } will be ${currencySymbol}${formatPrice(
        priceAfterDiscounts
      )}${currencyToDisplayOrEmptyIfUS}. Future payments will be ${currencySymbol}${formatPrice(
        item.price
      )}${currencyToDisplayOrEmptyIfUS}.`
    }
    return ''
  }

  const getPriceAfterItemDiscount = (couponDetails, priceBeforeDiscount) => {
    let priceAfterDiscount = priceBeforeDiscount

    if (couponDetails?.discountType === 'percentage') {
      const percentage = couponDetails.discountPercentage ?? 0
      priceAfterDiscount = priceBeforeDiscount * (1 - percentage / 100)
    } else if (couponDetails?.discountType === 'fixed_amount') {
      const discountAmount = couponDetails.discountAmount ?? 0
      priceAfterDiscount = priceBeforeDiscount - discountAmount
    }
    return priceAfterDiscount
  }

  const getSubtotalDiscountAmount = (subTotalPreDiscounts, subtotal) => {
    return subTotalPreDiscounts - subtotal
  }

  /**
   * the coupon can come in lower case, in upper case and both are valid, so check all combinations.
   * @param couponDetailsByCode
   * @param coupon
   * @returns {*|null}
   */
  const getCouponDetailsByCode = (couponDetailsByCode, coupon) => {
    try {
      const key = Object.keys(couponDetailsByCode).find(
        cd => cd?.toUpperCase() === coupon?.toUpperCase()
      )
      return couponDetailsByCode[key]
    } catch (e) {
      return null
    }
  }

  const isPriceIdOfProduct = ({ priceId, itemsToApplyDiscount, productId, isSubscription }) => {
    return !!products.find(someProduct => {
      return (
        productId === someProduct.id &&
        ((itemsToApplyDiscount[priceId].period && isSubscription) ||
          (!itemsToApplyDiscount[priceId].period && !isSubscription)) &&
        isPriceIdInProduct({ product: someProduct, priceId })
      )
    })
  }

  /**
   *
   * @param subTotalPreDiscounts
   * @param subtotal
   * @param items
   * @returns {{discountAmount: number, discountMessage: string}|{discountAmount: *, discountMessage: (string|string)}}
   */
  const getSubtotalDiscount = (subTotalPreDiscounts, subtotal, items) => {
    const couponDetails = getCouponDetailsByCode(couponDetailsByCode, coupon)
    if (!couponDetails || !isThereAValidCouponApplied) {
      return { discountAmount: 0, discountMessage: '' }
    }

    const itemsToApplyDiscount = items && Object.keys(items).length ? items : cartItems

    const priceIds = Object.keys(itemsToApplyDiscount)

    const dependentCouponDetails = getCouponDetailsByCode(couponDetailsByCode, dependentCouponCode)
    const freeGiftCouponDetails = lookForFreeGiftCouponDetails(
      [couponDetails?.id, dependentCouponDetails?.id],
      couponDetailsByCode
    )

    //we only consider that there is a free gift when coupon details has productsThatAllowFreeGiftWithPurchase and priceIdsForFreeWithCouponApplying.  Influencers coupon code keep the same behavior without any special behavior/message
    const isThereFreeProduct = !!freeGiftCouponDetails

    const discountAmount = getSubtotalDiscountAmount(subTotalPreDiscounts, subtotal)

    let isFreeProductConditionMet = false
    if (isThereFreeProduct) {
      isFreeProductConditionMet =
        freeGiftCouponDetails?.productsThatAllowFreeGiftWithPurchase?.some(
          ({ isSubscription, productId }) =>
            priceIds.some(priceId =>
              isPriceIdOfProduct({
                priceId,
                itemsToApplyDiscount,
                productId,
                isSubscription
              })
            )
        )
    }

    const discountMessage = getSubtotalDiscountMessage(
      itemsToApplyDiscount,
      isThereFreeProduct,
      isFreeProductConditionMet
    )

    return { discountAmount, discountMessage, isThereFreeProduct, isFreeProductConditionMet }
  }

  const getSubtotalDiscountMessage = (
    itemsToApplyDiscount,
    isThereFreeProduct,
    isFreeProductConditionMet
  ) => {
    //if no valid coupon
    if (!coupon || !isCouponValid) {
      return ''
    }

    const couponDetails = getCouponDetailsByCode(couponDetailsByCode, coupon)
    //if no couponDetails
    if (!couponDetails) {
      console.warn('there is not couponDetails for:' + coupon)
      return ''
    }

    if (isThereFreeProduct && !isFreeProductConditionMet) {
      return 'To redeem this offer, add the qualifying product to your cart before checkout.'
    }

    //if there's an invoiceDiscount
    if (couponDetails.applyOn === 'invoice_amount') {
      return ''
    }

    const itemsCount = Object.keys(itemsToApplyDiscount).length
    //if no items or no valid coupon
    if (!itemsCount) {
      return ''
    }

    let itemsWithApplicableCouponCount = 0
    Object.keys(itemsToApplyDiscount).forEach(priceId => {
      const item = lookForItem(priceId, itemsToApplyDiscount)
      if (isCouponApplicableToItem(coupon, item)) itemsWithApplicableCouponCount++
    })

    if (itemsWithApplicableCouponCount === 0) {
      return isCheckingCoupon
        ? ''
        : 'Please note that the discount does not apply to any of the items on your list.'
    }

    //if coupon applies to every item
    if (itemsWithApplicableCouponCount === itemsCount) {
      return ''
    }
    if (itemsWithApplicableCouponCount === 1 && itemsCount > 1) {
      return 'Note: Discount code only applies to one item in cart.'
    }
    if (itemsWithApplicableCouponCount > 1) {
      return 'Please note that the discount does not apply to all items on your list'
    }
    return ''
  }

  /**
   *
   * @param productId
   * @param priceBeforeDiscountFormatted
   * @param fullPriceFormatted
   * @param showOneTimePrice
   * @param showDiscountedCampaignPrice
   * @param byPassCouponAppliedCheck
   * @returns {{displayPrice: string, showPriceWithCouponDiscount: boolean, oldPriceFormatted}|{displayPrice: (*|number), showPriceWithCouponDiscount: unknown, oldPriceFormatted: (number|*)}}
   */
  const getPriceAfterDiscount = ({
    productId = SYSTEM_PRODUCT,
    priceBeforeDiscountFormatted,
    fullPriceFormatted,
    showOneTimePrice = false,
    showDiscountedCampaignPrice = false,
    byPassCouponAppliedCheck = false
  }) => {
    if (SYSTEM_PRODUCT === productId) {
      return getSystemPriceAfterDiscount({
        priceBeforeDiscountFormatted,
        fullPriceFormatted,
        showOneTimePrice,
        showDiscountedCampaignPrice,
        byPassCouponAppliedCheck
      })
    } else {
      // at the moment there is no discount for non System product
      return {
        displayPrice: priceBeforeDiscountFormatted,
        oldPriceFormatted: fullPriceFormatted,
        showPriceWithCouponDiscount: false
      }
    }
  }

  const getSystemPriceAfterDiscount = ({
    priceBeforeDiscountFormatted,
    fullPriceFormatted,
    showOneTimePrice = false,
    showDiscountedCampaignPrice = false,
    byPassCouponAppliedCheck = false
  }) => {
    // If the coupon is one of the first set campaigns, show discounted price
    const showPriceWithCouponDiscount = showDiscountedCampaignPrice && activeCampaign

    const campaignSystemPrice = showOneTimePrice
      ? activeCampaign?.oneTimePrice
      : activeCampaign?.systemPrice

    const campaignDisplayPrice =
      (coupon === activeCampaign?.promoCode || byPassCouponAppliedCheck) && campaignSystemPrice
        ? campaignSystemPrice
        : priceBeforeDiscountFormatted

    //it's added a 'isCouponValid' condition since
    // Without this condition, when it's one time selected, coupon is mark as invalid (in Cart coupon form), however the discount is applied in prices
    const couponAppliedCampaignPrice =
      coupon && isCouponValid && FIRST_SET_CAMPAIGNS[coupon.toUpperCase()]

    const displayPrice = couponAppliedCampaignPrice?.price ?? campaignDisplayPrice

    const oldPriceFormatted =
      showPriceWithCouponDiscount && activeCampaign?.noDiscountPrice
        ? activeCampaign.noDiscountPrice
        : fullPriceFormatted

    return {
      displayPrice,
      oldPriceFormatted,
      showPriceWithCouponDiscount
    }
  }

  const getSystemProductSavePrice = ({
    showOneTimePrice = false,
    showDiscountedCampaignPrice = false,
    byPassCouponAppliedCheck = false
  }) => {
    const systemProduct = getSystemProduct(products)
    const defaultSystemSubscriptionPrice = getDefaultSystemSubscriptionPrice(systemProduct).price
    const systemOneTimePrice = systemProduct.one_time_price.price
    const systemPriceToDisplay = formatPrice(
      showOneTimePrice ? systemOneTimePrice : defaultSystemSubscriptionPrice
    )
    const fullPrice = calcNonSystemProductsTotalPrice({
      products
    })

    const fullPriceFormatted = formatPrice(fullPrice)

    const { displayPrice, oldPriceFormatted, showPriceWithCouponDiscount } =
      getSystemPriceAfterDiscount({
        priceBeforeDiscountFormatted: systemPriceToDisplay,
        fullPriceFormatted,
        showOneTimePrice,
        showDiscountedCampaignPrice,
        byPassCouponAppliedCheck
      })

    return {
      displayPrice,
      fullPriceFormatted,
      oldPriceFormatted,
      showPriceWithCouponDiscount
    }
  }

  return {
    getDiscountByPriceId,
    getSubtotalDiscount,

    getSystemProductSavePrice,
    getPriceAfterDiscount
  }
}

export const withPriceDiscounts = WrappedComponent =>
  React.forwardRef((props, ref) => {
    const { getDiscountByPriceId, getSubtotalDiscount } = usePriceWithDiscounts()
    return (
      <WrappedComponent
        ref={ref}
        {...props}
        getDiscountByPriceId={getDiscountByPriceId}
        getSubtotalDiscount={getSubtotalDiscount}
      />
    )
  })

export default usePriceWithDiscounts
