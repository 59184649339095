import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { breakpoints } from '../styles/theme-proven/responsive'
import { MIN_SIDE_BAR_WIDTH_IN_PIXELS_NUMBER } from '../components/account/dashboard/shared/side-bar'

/**
 * It determines dashboard mode (mobile or not mobile mode) based on a specific breakpoint calculated with the sidebar width and md breakpoint
 * @returns True if dashboard has to use mobile mode
 */
const useDashboardMobileMode = () => {
  const isDashboardMobileMode = useMediaQuery(theme =>
    theme.breakpoints.down(breakpoints.md + MIN_SIDE_BAR_WIDTH_IN_PIXELS_NUMBER)
  )
  return isDashboardMobileMode
}

export const withDashboardMobileMode = WrappedComponent => props => {
  const isDashboardMobileMode = useDashboardMobileMode()
  return <WrappedComponent {...props} isDashboardMobileMode={isDashboardMobileMode} />
}

export default useDashboardMobileMode
