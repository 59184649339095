import React from 'react'
import { AuthContext } from 'providers/AuthProvider'

function withAuth(Component) {
  return function WithAuthRenderer(props) {
    return (
      <AuthContext.Consumer>
        {authProps => <Component {...props} {...authProps} />}
      </AuthContext.Consumer>
    )
  }
}

export default withAuth
