import React from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const useWidth = () => {
  const theme = useTheme()
  const keys = [...theme.breakpoints.keys].reverse()
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key))
      return !output && matches ? key : output
    }, null) || 'xs'
  )
}

export const withWidth = WrappedComponent =>
  React.forwardRef((props, ref) => {
    const width = useWidth()
    return <WrappedComponent ref={ref} {...props} width={width} />
  })

export default useWidth
