import { useMediaQuery } from '@mui/material'

// useBuilderStyles
//
// Accepts a Builder block and returns the styles set within Builder for the current breakpoint.
//
// Optionally accepts a style allowlist array, which contains a list of CSS rules that
// you want to return from the Builder styles. Useful for when you want to pass through
// certain styles to a block's underlying component.

const BUILDER_SIZE_SMALL = 'small'
const BUILDER_SIZE_MEDIUM = 'medium'
const BUILDER_SIZE_LARGE = 'large'

const getDeviceSizeForBuilder = (isMdUp, isLgUp) => {
  if (isLgUp) {
    return BUILDER_SIZE_LARGE
  } else if (isMdUp) {
    return BUILDER_SIZE_MEDIUM
  } else {
    return BUILDER_SIZE_SMALL
  }
}

export const useBuilderStyles = (builderBlock, styleAllowlist) => {
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'))
  const isLgUp = useMediaQuery(theme => theme.breakpoints.up('lg'))
  // Merge in Builder styles...

  // there is a bug that builder return a wrong deviceSize
  //const deviceSize = builderState.state.deviceSize
  // fix it using MUI breakpoint
  const deviceSize = getDeviceSizeForBuilder(isMdUp, isLgUp)
  let builderStyles = builderBlock.responsiveStyles.large || {}

  if (deviceSize === BUILDER_SIZE_MEDIUM || deviceSize === BUILDER_SIZE_SMALL)
    builderStyles = { ...builderStyles, ...(builderBlock.responsiveStyles.medium || {}) }
  if (deviceSize === BUILDER_SIZE_SMALL)
    builderStyles = { ...builderStyles, ...(builderBlock.responsiveStyles.small || {}) }

  // ...but only keep specific rules that won't break our design system.
  if (styleAllowlist) {
    builderStyles = Object.fromEntries(
      Object.entries(builderStyles).filter(([k]) => styleAllowlist.includes(k))
    )
  }

  return builderStyles
}
