import { useEffect } from 'react'
import { userSourceSelect } from '../utils/selectors'
import { APP_EDITION, APP_EDITION_PROVEN, APP_EDITION_SEPHORA } from '../constants/constants'
import { setAppEdition } from '../actions/app.actions'
import { useDispatch, useSelector } from 'react-redux'

function useForAppEdition() {
  const dispatch = useDispatch()
  const userSource = useSelector(userSourceSelect)

  useEffect(() => {
    // on app load - set APP EDITION flag in redux store based on server's ENV VARIABLE
    if (APP_EDITION.includes(APP_EDITION_SEPHORA)) {
      dispatch(setAppEdition(APP_EDITION_SEPHORA))
    }
  }, [dispatch])

  useEffect(() => {
    // when user source changes - update APP EDITION flag in redux store
    if (userSource === APP_EDITION_SEPHORA) {
      dispatch(setAppEdition(APP_EDITION_SEPHORA))
    } else if (userSource === APP_EDITION_PROVEN) {
      dispatch(setAppEdition(APP_EDITION_PROVEN))
    } else {
      dispatch(setAppEdition(APP_EDITION)) // no source flag -> use ENV VAR
    }
  }, [userSource])
}

export default useForAppEdition
