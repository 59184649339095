import { useLocation } from 'react-router'

export default function useCheckRUDPage() {
  const { pathname } = useLocation()

  return (
    pathname.includes('/account') &&
    !pathname.includes('/account/congratulations') &&
    !pathname.includes('/account/thank-you') &&
    !pathname.includes('/account/congratulations/eye') &&
    !pathname.includes('/account/congratulations/serum') &&
    !pathname.includes('/account/coming-soon') &&
    !pathname.includes('/account/subscriptions/reactivate')
  )
}
