import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { campaigns } from 'constants/configs/campaigns'
import { activeCampaignSelector } from 'utils/selectors'

function useActiveCampaign() {
  const activeCampaign = useSelector(activeCampaignSelector)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const activeCampaignParam = searchParams.get('active_campaign')
  // for debugging purposes only
  if (activeCampaignParam) {
    return campaigns.find(someCampaign => someCampaign.id === activeCampaignParam)
  }
  return activeCampaign
}

export default useActiveCampaign
