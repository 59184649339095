import { useState, useEffect, useCallback } from 'react'

export const useBuilderResource = (
  clientFetch,
  clientFetchDeps,
  callback = null,
  callbackDeps = [],
  options = {
    isReadyToGetResource: true
  }
) => {
  const isReadyToGetResource =
    options && 'isReadyToGetResource' in options ? options.isReadyToGetResource : true //by default isReadyToGetResource is true no matter if it is passed an empty object in 'options' ( {} )

  const [resource, setResource] = useState(null)
  const [loading, setLoading] = useState(true)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedClientFetch = useCallback(clientFetch, clientFetchDeps)
  const memoizedCallback = useCallback(async resource => {
    if (typeof callback === 'function') {
      const result = await callback(resource)

      return result ?? resource
    }
    return resource
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, callbackDeps)

  useEffect(() => {
    const getResource = async () => {
      if (typeof window !== 'undefined' && isReadyToGetResource) {
        let resource = await memoizedClientFetch()
        resource = await memoizedCallback(resource)
        setResource(resource)
        setLoading(false)
      }
    }
    getResource()
  }, [memoizedCallback, memoizedClientFetch, isReadyToGetResource])

  return { resource, loading }
}
